import React, { useState } from "react";
import { Link } from "gatsby";
import Helmet from 'react-helmet';
import Layout from '../components/layout';
import favicon from '../images/favicon.ico';
import { Slide, Flip  } from 'react-reveal';
import Wave from 'react-wavify';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { InPageBot } from "@leadoo/react-bot";

// images
import banner_img from "../images/skanner.svg";
import skicka_faktura_safe from "../images/skicka_faktura_safe.png";
import attest_auto from "../images/attest_auto.png";
import attest_flow from "../images/attest_flow.png";
import dokumentskannrar from "../images/dokumentskannrar.svg";
import speed from "../images/speed.svg";

const BOT_CODE = "NUbkN9AF";

function Skanner() {
    const [chatbot_1, setChatbot_1] = useState(false);
    const [kontakt_2, set_kontakt_2] = useState(false);
    const [kontakt_3, set_kontakt_3] = useState(false);

    return(
        <Layout>
            <Helmet>
                <title>Skanners | Optosweden</title>
                <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
                <meta name="description" content="Optosweden erbjuder dokumentskannrar med bäst prestanda som är snabba och kan göra duplex-skanning." />
                <meta name="keywords" content="dokumentskannrar | skanner | produktionsskannrar | duplex-skanning" />
                <meta property="og:title" content="Optosweden AB" />
                <meta property="og:tyope" content="website" />
                <meta property="og:discription" content="Optosweden erbjuder dokumentskannrar med bäst prestanda som är snabba och kan göra duplex-skanning." />
                <meta property="og:image" content=" " />
                <meta property="og:locale" content="sv_SE" />
                <meta property="og:url" content="https://www.optosweden.se/skanners" />
                <link rel="cannonical" href="https://www.optosweden.se/skanners" />
            </Helmet>

            <section>
                <div className="w-screen bg-white border-t border-gray-100">
                    <div className="container mx-auto px-4 xl:px-24">
                        <div className="grid lg:grid-cols-2 py-24 gap-20">
                            <Slide left>
                                <div className="flex flex-col items-start justify-center">
                                    <h1 className="title-font font-semibold text-3xl text-blue-custome">Skannrar som erbjuder bäst prestanda</h1>
                                    <p className="leading-relaxed mt-8 text-lg text-gray-600">
                                        Vi har ett stort utbud av olika skannrar och vi har med 
                                        omsorg valt ut de som erbjuder bäst prestanda för ändamålet.
                                        Gemensamt är att de har en stabil dokumentmatning 
                                        och skapar bra bilder av dokumenten.
                                        De flesta skannrarna har en lång historik med 
                                        tidigare modeller som har förbättrats med tiden.
                                    </p>
                                    <button onClick={() => setChatbot_1(true)} className="px-10 py-2 rounded-full mt-10 text-white bg-blue-custome shadow-md hover:opacity-90">Kom i kontakt</button>
                                    {chatbot_1 
                                        ? 
                                            <div className="h-full w-full mt-10">
                                                <InPageBot code={BOT_CODE} seamless/>
                                            </div> 
                                        : "" 
                                    }
                                </div>
                            </Slide>
                            <Slide right>
                                <div className="w-1/2 p-4 mx-auto">
                                    <img className="w-full" src={banner_img} alt="service och support optosweden" />
                                </div>
                            </Slide>
                        </div>
                    </div>
                    <Wave 
                        className="-mb-2"
                        fill='#F3F4F6'
                        paused={false}
                        options={{
                        height: 20,
                        amplitude: 50,
                        speed: 0.15,
                        points: 3
                        }}
                    />
                </div>
            </section>



            <section className="bg-gray-100">
                <div className="container mx-auto px-4 xl:px-24">
                    <div className="pb-10 flex text-md">
                        <Link to="/" className="flex items-center text-blue-800 hover:text-blue-600">Hem</Link>
                        <span className="text-gray-400 px-3">/</span>
                        <span className="text-gray-400">skanners</span>
                    </div>
                    <div className="grid md:grid-cols-3 gap-8">
                        <AnchorLink href="#skickaFaktura_article">
                            <div className="bg-white rounded-md">
                                <div className="w-full px-6 py-8 ">
                                    <div className="w-40 mx-auto">
                                        <img className="" src={skicka_faktura_safe} alt="ta emot e-fakturor"/>
                                    </div>
                                    <p className="mt-4 text-center text-gray-500">
                                        Vi har skannrar med absolut högsta dokumentmatarkapacitet.
                                    </p>
                                </div>
                            </div>
                        </AnchorLink>
    
                        <AnchorLink href="#skickaFaktura_article">
                            <div className="bg-white rounded-md">
                                <div className="w-full px-6 py-8 ">
                                    <div className="w-40 mx-auto">
                                        <img className="" src={attest_auto} alt="ta emot e-fakturor"/>
                                    </div>
                                    <p className="mt-4 text-center text-gray-500">
                                        Våra skannrar passar både små och stora företag med små eller stora volymer. 
                                    </p>
                                </div>
                            </div>
                        </AnchorLink>

                        <AnchorLink href="#skickaFaktura_article">
                            <div className="bg-white rounded-md">
                                <div className="w-full px-6 py-8 ">
                                    <div className="w-40 mx-auto">
                                        <img className="" src={attest_flow} alt="ta emot e-fakturor"/>
                                    </div>
                                    <p className="mt-4 text-center text-gray-500">
                                        Vi erbjuder dokumentskannrar med sorteringsmöjligheter.
                                    </p>
                                </div>
                            </div>
                        </AnchorLink>
                    </div>
                </div>
            </section>



            <section className="bg-gray-100 pt-32 pb-24" id="skickaFaktura_article" >
                <div className="container mx-auto px-4 xl:px-24" >
                    <hr className="text-gray-200"/>
                </div>
            </section>


            <section className="bg-gray-100" >
                <div className="container mx-auto px-4 xl:px-24">
                    <div className="grid lg:grid-cols-2 gap-20">
                        <Slide left>
                            <div className="h-full w-2/3 p-4 mx-auto">
                                <img className="w-full" src={dokumentskannrar} alt="PaperStream Capture Pro" />
                            </div>
                        </Slide>
                        <Slide right>
                            <div className="flex flex-col items-start justify-center">
                                <h1 className="title-font font-semibold text-3xl text-gray-900">Skannrar som passar just er</h1>
                                <p className="leading-relaxed mt-8 text-lg text-gray-600">
                                    Våra skannrar passar både små och stora 
                                    företag med små eller stora volymer.
                                    Vi kan bl.a. erbjuda dokumentskannrar med 
                                    sorteringsmöjligheter om ni t.ex. vill 
                                    sortera ut separationsblad, som ni sedan kan återanvända.
                                    Vi har de mer "personliga" dokumentskannrarna som 
                                    tar liten plats och kan placeras på skrivbordet 
                                    med en dokumentmatarkapacitet på 80 st dokument.
                                    Vidare har vi skannrar med absolut högsta 
                                    dokumentmatarkapacitet, våra produktionsskannrar, 
                                    på upp till 2000 st dokument.
                                </p>
                                <button className="px-7 py-2 rounded-full mt-10 text-blue-custome border  bg-white shadow-md hover:opacity-90" onClick={() => set_kontakt_2(true)}>Kom i kontakt</button>
                                
                                {kontakt_2 
                                    ? 
                                        <div className="h-full w-full mt-10">
                                            <InPageBot code={BOT_CODE} seamless/>
                                        </div> 
                                    : "" 
                                }
                            </div>
                        </Slide>
                    </div>
                </div>
            </section>

          

            <section className="bg-gray-100 py-28">
                <div className="container mx-auto px-4 xl:px-24" >
                    <hr className="text-gray-200"/>
                </div>
            </section>


            <section className="bg-gray-100 pb-32" >
                <div className="container mx-auto px-4 xl:px-24">
                    <div className="grid lg:grid-cols-2 gap-20">
                        <Slide left>
                            <div className="flex flex-col items-start justify-center">
                                <h1 className="title-font font-semibold text-3xl text-gray-900">Hastigheten &amp; duplex-skanning</h1>
                                <p className="leading-relaxed mt-8 text-lg text-gray-600">
                                    Hastigheten för de skannrar som vi erbjuder 
                                    ligger mellan 20 st papper per minut (40 st 
                                    bilder per minut*) och 300 st papper per 
                                    minut (600 st bilder per minut*).
                                    <br/><br/>
                                    Vi har garanterat en skanner som passar just er!
                                    <br/><br/>
                                    Våra dokumentskannrar skapar en 
                                    bild av både framsida och baksida 
                                    (duplex-skanning).
                                </p>
                                <button className="px-7 py-2 rounded-full mt-10 text-white bg-blue-custome  shadow-md hover:opacity-90" onClick={() => set_kontakt_3(true)}>Mer info, kontakta oss här!</button>
                                {kontakt_3 
                                    ?
                                        <div className="h-full w-full mt-10">
                                            <InPageBot code={BOT_CODE} seamless/>
                                        </div> 
                                    : "" 
                                }
                            </div>
                        </Slide>
                        <Slide right>
                            <div className="w-2/3  p-4 mx-auto">
                                <img className="w-full" src={speed} alt="konvertera skannade information" />
                            </div>
                        </Slide>
                    </div>
                </div>
            </section>



            <section className="py-32">
                {/*-------------- other services - START ---------------*/}
                <div className="container mx-auto px-4 xl:px-24">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-20">
                        <Flip top>
                            <div className="px-8 md:px-0">
                                <div className="bg-blue-100 rounded-full w-16 h-16 flex justify-center items-center text-blue-custome shadow-2xl">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="currentColor" viewBox="0 0 16 16">
                                        <path d="M1.5 1a.5.5 0 0 0-.5.5v3a.5.5 0 0 1-1 0v-3A1.5 1.5 0 0 1 1.5 0h3a.5.5 0 0 1 0 1h-3zM11 .5a.5.5 0 0 1 .5-.5h3A1.5 1.5 0 0 1 16 1.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 1-.5-.5zM.5 11a.5.5 0 0 1 .5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 1 0 1h-3A1.5 1.5 0 0 1 0 14.5v-3a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v3a1.5 1.5 0 0 1-1.5 1.5h-3a.5.5 0 0 1 0-1h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 1 .5-.5zM3 4.5a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-7zm3 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7z"/>
                                    </svg>
                                </div>
                                <h2 className="uppercase mt-6 text-blue-custome font-medium mb-3">Skanningsprogramvara</h2>
                                <p className="font-light text-sm text-gray-500">
                                    Hos oss kan ni välja den programvara för bildfångst som passar just 
                                    era behov. Samtidigt kan ni vara säkra på att ni får hjälp i 
                                    ert val från våra kunniga och erfarna säljare, tekniker och konsulter.
                                </p>
                                <Link to="/skanningsprogramvara" className="mt-3 text-blue-custome flex items-center hover:text-blue-600">
                                    Läs mer
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20"  fill="currentColor">
                                        <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                                    </svg>
                                </Link>
                            </div>
                        </Flip>
                        
                        <Flip top>
                            <div className="px-8 md:px-0">
                                <div className="bg-blue-100 rounded-full w-16 h-16 flex justify-center items-center text-blue-custome shadow-2xl">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="currentColor" viewBox="0 0 16 16">
                                        <path d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V9H2V2a2 2 0 0 1 2-2zm5.5 1.5v2a1 1 0 0 0 1 1h2l-3-3zM2 12h12v2a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2v-2zM.5 10a.5.5 0 0 0 0 1h15a.5.5 0 0 0 0-1H.5z"/>
                                    </svg>
                                </div>
                                <h2 className="uppercase mt-6 text-blue-custome font-medium mb-3">Intelligenta automationsplattformar</h2>
                                <p className="font-light text-sm text-gray-500">
                                    Med marknadsledande teknik analyserar vi dina dokument 
                                    oavsett format. Vi läser ut den data du behöver och gör 
                                    den tillgänglig för dig.
                                </p>
                                <Link to="/dokument" className="mt-3 text-blue-custome flex items-center hover:text-blue-600">
                                    Läs mer
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20"  fill="currentColor">
                                        <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                                    </svg>
                                </Link>
                            </div>
                        </Flip>
                    
                        <Flip top>
                            <div className="px-8 md:px-0">
                                <div className="bg-blue-100 rounded-full w-16 h-16 flex justify-center items-center text-blue-custome shadow-2xl">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="currentColor" viewBox="0 0 16 16">
                                        <path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z"/>    
                                    </svg>
                                </div>
                                <h2 className="uppercase mt-6 text-blue-custome font-medium mb-3">Service &amp; Support</h2>
                                <p className="font-light text-sm text-gray-500">
                                    Vi hjälper dig! Vår service-och supportavdelning hjälper er 
                                    med företagets skanner och mjukvara. Vi erbjuder service 
                                    och support men även utbildning på er skanner och mjukvara 
                                    i hela Norden.
                                </p>
                                <Link to="/service" className="mt-3 text-blue-custome flex items-center hover:text-blue-600">
                                    Läs mer
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20"  fill="currentColor">
                                        <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                                    </svg>
                                </Link>
                            </div>
                        </Flip>
                    </div>  
                </div> 
                {/*-------------- other services - END ---------------*/}
            </section>
        </Layout>
    );
}
export default Skanner;